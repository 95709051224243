<script lang="ts">
  import { onMount } from 'svelte'
  import { flip } from 'svelte/animate'
  import { writable } from 'svelte/store'

  import { debounce } from 'common/src/utils'

  import { Button, Popover } from 'carbon-components-svelte'
  import Settings from 'carbon-icons-svelte/lib/Settings.svelte'
  import OverflowMenuHorizontal from 'carbon-icons-svelte/lib/OverflowMenuHorizontal.svelte'
  import Chemistry from 'carbon-icons-svelte/lib/Chemistry.svelte'

  import { state } from '../../store/state'
  import { navigate, router } from '../../routing/router'
  import { getMenuItemFromHash, recoverSidebar, sidebarItems, storeSidebar } from './sidebar'
  import MyButton from '../atoms/my-button.svelte'

  import { LiveblogKind } from '../../store/kind'
  import { reportInfo } from '../../store/notifications'

  const unimplemented = [LiveblogKind.nameOnState, 'events', 'longforms', 'settings']

  // TODO: remove
  const { experimental } = state

  const tabs = state.tabs
  let hovering: number | boolean | null = false

  let windowHeight: number
  let sItems = sidebarItems

  let buttons = sItems.map(() => undefined as HTMLDivElement | undefined)
  export let hiddenButtons = sItems.map((item) => {
    return {
      ...item,
      show: false,
    }
  })

  let open = false
  let ref: any = null
  let showDots = writable(false)

  const setWindowSizes = () => {
    windowHeight = window.innerHeight as number

    buttons.forEach((button, index) => {
      const offset = 2 * 48 + 2 * 8
      if (!button) return
      const rect = button.getBoundingClientRect()

      const sum = rect.bottom + offset
      const visible = sum <= windowHeight

      button.style.visibility = visible ? 'visible' : 'hidden'
      hiddenButtons[index].show = visible ? false : true
    })

    showDots.set(buttons.find((button) => button?.style.visibility === 'hidden') !== undefined)
  }

  const debounced = debounce(setWindowSizes, 300)

  const drop = (event: any, target: any) => {
    event.dataTransfer.dropEffect = 'move'
    const start = parseInt(event.dataTransfer.getData('text/plain'))

    const reorder = (list: Array<any>) => {
      if (start < target) {
        list.splice(target + 1, 0, list[start])
        list.splice(start, 1)
      } else {
        list.splice(target, 0, list[start])
        list.splice(start + 1, 1)
      }
    }

    reorder(sItems)
    reorder(hiddenButtons)
    storeSidebar(state, sItems)

    hovering = null
  }

  const dragstart = (event: any, i: number) => {
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.dropEffect = 'move'
    const start = i
    event.dataTransfer.setData('text/plain', start)
  }

  let selected = ''

  const unsubscribe = state.currentHash.subscribe((hash) => {
    selected = getMenuItemFromHash(hash)
  })

  const handleDragEnd = () => {
    hovering = false
  }

  const buttonSwitch = (item: any, index: number) => {
    let lastVisible = buttons.map((button) => button?.style.visibility).indexOf('hidden') - 1

    hiddenButtons[lastVisible].show = true

    const setVisibility = (index: number, value: string) => {
      const toUpdate = buttons[index]
      if (toUpdate) {
        toUpdate.style.visibility = value
      }
    }

    setVisibility(lastVisible, 'hidden')

    hiddenButtons[index].show = false
    setVisibility(index, 'visible')

    const temp = sItems[lastVisible]
    sItems[lastVisible] = sItems[index]
    sItems[index] = temp

    const tempHiddenButtons = hiddenButtons[lastVisible]
    hiddenButtons[lastVisible] = hiddenButtons[index]
    hiddenButtons[index] = tempHiddenButtons

    handleClickRoute(item)
  }

  const handleClickRoute = (item: any) => {
    tabs.unselect()
    selected = item.id
    navigate(item.path, state)
  }

  const revertExperimental = () => {
    state.experimental.set(false)
    router(state).deleteOneParam('experimental').andStay()
    reportInfo('Experimental features disabled', state)
  }

  onMount(() => {
    recoverSidebar(state)
    window.addEventListener('resize', debounced)
    setWindowSizes()
    return () => {
      window.removeEventListener('resize', debounced)
      unsubscribe()
    }
  })
</script>

<div
  class="sidebar flex flex-col w-12 h-full {$experimental ? 'experimentalColor' : ''}"
  style={`--saved-search-visibility: ${$experimental ? 'visible' : 'hidden'}`}
>
  {#each sidebarItems as item, index (item.id)}
    <div
      class:hidden={unimplemented.includes(item.id)}
      class="foreground relative"
      animate:flip={{ duration: 300 }}
    >
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        bind:this={buttons[index]}
        class="list-item relative flex"
        draggable={true}
        on:dragstart={(event) => dragstart(event, index)}
        on:drop|preventDefault={(event) => drop(event, index)}
        on:dragover|preventDefault={() => false}
        on:dragenter={() => (hovering = index)}
        on:dragend={() => handleDragEnd()}
        class:drop-here={hovering === index}
      >
        {#if item.id === 'home' && $experimental}
          <button class="experimental flex" on:dblclick={revertExperimental}>
            <Chemistry size={20} class="items-center" />
          </button>
        {/if}
        <MyButton
          id={`qa-test-${item.id}`}
          kind="ghost"
          className={`${item.id === 'home' ? 'home' : 'item'}-button`}
          isSelected={selected === item.id}
          tooltip={item.id.replace(/-/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}
          tooltipPosition="right"
          disabled={false}
          tooltipDelay={1000}
          icon={item.icon}
          onClick={() => {
            handleClickRoute(item)
          }}
        />
      </div>
      <div class:background={selected === item.id} />
    </div>
  {/each}
</div>

<div class="fixed">
  {#if $showDots}
    <div bind:this={ref} data-outline id="popover-container" class="fixed bottom-14 left-0">
      <Button
        class="accent-black button-style "
        iconDescription="Additional Views"
        tooltipPosition="right"
        on:click={() => (open = !open)}
        kind="ghost"
        icon={OverflowMenuHorizontal}
      />
      <Popover
        caret
        bind:open
        align="right-bottom"
        on:click:outside={({ detail }) => {
          open = ref.contains(detail.target)
        }}
      >
        {#each hiddenButtons as item, index}
          {#if item.show && !unimplemented.includes(item.id)}
            <div id={item.id} class="w-28">
              <Button
                class="hidden-button-style"
                size="small"
                kind="ghost"
                on:click={() => buttonSwitch(item, index)}
                >{item.id}
              </Button>
            </div>
          {/if}
        {/each}
      </Popover>
    </div>
  {/if}
  <div class:hidden={unimplemented.includes('settings')} class="fixed left-0 bottom-2.5">
    <Button
      kind="ghost"
      iconDescription="Settings"
      class="button-style"
      tooltipPosition="left"
      icon={Settings}
    />
  </div>
</div>

<style lang="postcss">
  .sidebar > .foreground:nth-child(2) {
    @apply mt-10;
  }
  .drop-here {
    border: 1px solid black;
  }

  .sidebar > :not(div:nth-child(1)) {
    @apply mt-[0.1rem];
  }

  .sidebar div :global(.item-button svg) {
    @apply w-6 h-6;
  }

  .sidebar div :global(.hidden-button-style) {
    @apply w-28 py-0 text-center text-black;
  }

  .sidebar :global(.bx--assistive-text) {
    z-index: 1000;
  }

  .sidebar :global(.item-button) {
    @apply px-[0.688rem];
  }

  .sidebar :global(.home-button) {
    @apply px-[0.375rem] pt-[0.813rem] pb-0;
  }

  .sidebar :global(.home-button svg) {
    @apply h-9 w-9;
  }
  .sidebar.experimentalColor :global(.home-button svg path.st0) {
    fill: #44f20a;
  }
  .sidebar :global(.my-button) {
    @apply bg-transparent border-none shadow-none;
  }

  .sidebar :global(.my-button-selected svg) {
    @apply bg-transparent;
  }

  .background {
    min-height: 38px;
    background: rgba(var(--accent-rgb), 0.5);
    z-index: -1;
    position: absolute;
    border-radius: var(--round);
    padding: 5px;
    top: 0;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 37px;
    height: 37px;
  }

  .experimental {
    background-color: transparent;
    position: absolute;
    padding: 3.2rem 0 0 0.8rem;
  }

  /* TODO: remove */
  .sidebar :global(#qa-test-saved-search) {
    visibility: var(--saved-search-visibility);
  }
</style>
